<template>
  <div class="cancelOrder">
    <Header :isGoBack="false" path="/" :title="title" theme="light" />
    <!-- 待取消畫面 -->
    <div class="zone__main" v-if="isCancel == false">
      <div class="text_01">
        取消交易不会自动退款
      </div>
      <div class="text_02">
        一天内取消3笔交易将会限制您当天的买入功能
      </div>
      <div class="box__img box__img__row">
        <img src="../../assets/images/public/ic_cancel_the_order.webp" alt="" />
      </div>
      <div class="btn__01" @click="$router.go(-1)">
        暂不取消
      </div>
      <div class="btn__02" @click="cancelOrder()">
        立即取消
      </div>
    </div>
    <!-- 確定取消 -->
    <div class="zone__main__02" v-else>
      <div class="content_2">
        <div class="box__close mb_8px">
          <div class="box__img" @click="$router.push('/')">
            <img src="../../assets/images/public/ic_order_cancel_the_order.png" alt="" />
          </div>
          <div class="text__01">订单已取消</div>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            卖家名称 : <span>{{ logData["traderName"] }}</span>
          </div>
          <div class="save" v-if="isTraderRelations != true"
            @click="postTraderRelations({ traderId: logData['traderId'] })">
            [收藏]
          </div>
        </div>
        <div class="w100 mb_8px">取消类型 : <span>订单取消</span></div>
        <div class="w100 mb_8px">
          订单金额 : <span>{{ logData["orderAmount"] }}</span>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            订单编号 : <span>{{ logData["orderNo"] }}</span>
          </div>
          <div v-clipboard:copy="logData['orderNo']" v-clipboard:success="handleCopySuccess"
            v-clipboard:error="handleCopyError" style="white-space:nowrap;">
            [复制]
          </div>
        </div>
        <div class="w100 mb_8px">
          创建时间 : <span>{{ logData["createdAt"] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions } from "vuex";
import { Message } from "element-ui";
import Cookies from "js-cookie";
export default {
  name: "cancelOrder",
  components: {
    Header,
  },
  data() {
    return {
      title: "取消訂單",
      data: [],
      isTraderRelations: false,
      isCancel: false,
      hideGoBack: false,
    };
  },
  computed: {
    ...mapState("orders", ["buySubmitData", "logData"]),
    ...mapState("trader", ["traderRelationsData"]),
  },
  watch: {
    traderRelationsData() {
      this.findTraderRelations();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("orders", ["putBuyCancel", "getLogId"]),
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    init() {
      const self = this;
      this.getTraderRelations({});
      self.data = {
        amount: self.buySubmitData["amount"],
        orderNo: self.buySubmitData["orderNo"],
        seller: self.buySubmitData["seller"],
        account: self.buySubmitData["traderAccounts"][0]["account"],
        channelCode: self.buySubmitData["traderAccounts"][0]["channelCode"],
        accountName: self.buySubmitData["traderAccounts"][0]["accountName"],
        channelName: self.buySubmitData["traderAccounts"][0]["channelName"],
      };
    },
    cancelOrder() {
      Cookies.remove("buySubmitData");
      this.title = "訂單取消";
      this.hideGoBack = true;
      let data = {
        orderNo: this.$route.params.orderNo,
      };
      this.putBuyCancel(data);
      this.getLogId(this.buySubmitData.orderId);
      this.isCancel = true;
    },
    findTraderRelations() {
      const self = this;
      this.traderRelationsData.forEach((e) => {
        if (e.traderId == this.logData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
    handleCopySuccess() {
      Message({
        message: "复制成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cancelOrder {
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: scroll;

  .zone__main {
    width: 100%;
    padding: 4rem 1rem;

    @media (min-width:480px) {
      padding: 1rem 1rem;
    }

    .text_01 {
      font-weight: bold;
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    .text_02 {
      color: var(--gray);
      font-size: 0.9rem;
      padding-bottom: 5rem;

      @media (min-width:480px) {
        padding-bottom: 1rem;
      }
    }

    .box__img {
      width: 50%;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .btn__01,
    .btn__02 {
      width: 100%;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      border-radius: 20px;
      margin: auto;

      @media (min-width:480px) {
        width: 50%;
      }
    }

    .btn__01 {
      background-color: var(--orange);
      color: var(--white);
      margin-top: 6rem;
      margin-bottom: 1rem;
    }

    .btn__02 {
      background-color: var(--deep-gray);
      color: var(--light-black);
    }
  }

  .zone__main__02 {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 1rem;

    .content_2 {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding-top: 1.5rem;
      flex-wrap: wrap;
      font-size: 0.8rem;

      &>div {
        width: 100%;
        text-align: left;
        color: var(--gray);

        div {
          color: var(--gray);
        }

        span {
          color: var(--black);
        }

        .save {
          color: var(--orange);
        }
      }

      .box__close {
        width: 100%;

        .box__img {
          width: 2rem;
          margin: auto;

          img {
            width: 100%;
          }
        }

        .text__01 {
          margin-top: 0.5rem;
          color: var(--font-gray);
          text-align: center;
        }
      }
    }
  }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.box__img__row {
  @media (min-width:480px) {
    display: none;
  }
}
</style>
